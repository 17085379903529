import { createStore } from 'vuex';
import offers from './modules/offers';
import crud from './modules/crud';
import wiki from './modules/wiki';
import form from './modules/form';
import questionnaire from './modules/questionnaire';
import auth from './modules/auth';
import publishers from './modules/publishers';
import advertisers from './modules/advertisers';
import offer from './modules/offer';
import reconciliations from './modules/reconciliations';
import aiChat from './modules/aiChat';
import matchup from './modules/matchup';

const store = createStore({
    modules: {
        crud,
        offers,
        wiki,
        form,
        questionnaire,
        auth,
        publishers,
        advertisers,
        offer,
        reconciliations,
        aiChat,
        matchup
    }
});

export default store;
