export default [
    {
        path: '/advertiser',
        meta: { title: 'MyMadrivo | Advertisers' },
        children: [
            {
                path: '/internal/advertiser',
                name: 'advertiserSearch',
                component: () => import('@/views/pages/Advertisers/Index.vue'),
                meta: { isInternal: true, requiresAuth: true }
            },
            {
                path: '/advertiser/:id',
                name: 'advertiserDashboard',
                props: (route) => ({ id: route.params.id }),
                component: () => import('@/views/pages/Advertisers/Dashboard.vue'),
                meta: { isInternal: false, requiresAuth: true }
            },
            {
                path: '/advertiser/:id/newoffer',
                name: 'advertiserNewOfferRequest',
                props: (route) => ({ id: route.params.id }),
                component: () => import('@/views/pages/Advertisers/NewOfferRequest.vue'),
                meta: { isInternal: false, requiresAuth: true }
            },
            {
                path: '/advertiser/offer/:id',
                name: 'offerDetails',
                props: (route) => ({ id: route.params.id }),
                component: () => import('@/views/pages/Advertisers/OfferDetails.vue'),
                meta: { isInternal: false, requiresAuth: true }
            },
            {
                path: '/internal/advertiser/system-credentials',
                name: 'advertiserSystemCredentials',
                component: () => import('@/views/pages/Advertisers/SystemCredentials.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'manageAdvertiserSystemCredentials' }
            }
        ]
    },

    {
        path: '/advertiser/onboarding',
        meta: { title: 'MyMadrivo | Advertiser Onboarding' },
        children: [
            {
                path: '/internal/advertiser/applications',
                name: 'advertiserApplications',
                component: () => import('@/views/pages/AdvertiserOnboarding/ApplicationList.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'manageAdvertiserApplications' }
            },
            {
                path: '/advertiser/onboarding/:uuid',
                name: 'advertiserOnboarding',
                props: (route) => ({ uuid: route.params.uuid }),
                component: () => import('@/views/pages/AdvertiserOnboarding/Index.vue')
            },
            {
                path: '/advertiser/onboarding/:uuid/funding',
                name: 'advertiserOnboardingFunding',
                props: (route) => ({ uuid: route.params.uuid }),
                component: () => import('@/views/pages/AdvertiserOnboarding/Funding.vue')
            },
            {
                path: '/advertiser/onboarding/:uuid/agreement',
                name: 'advertiserOnboardingAgreement',
                props: (route) => ({ uuid: route.params.uuid }),
                component: () => import('@/views/pages/AdvertiserOnboarding/Agreement.vue')
            },
            {
                path: '/advertiser/onboarding/:uuid/agreement/signed',
                name: 'advertiserOnboardingAgreementSigned',
                props: (route) => ({ uuid: route.params.uuid }),
                component: () => import('@/views/pages/AdvertiserOnboarding/SignedAgreement.vue')
            }
        ]
    }
];
