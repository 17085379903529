import { getData } from '@/api';

const state = {
    isLoadingData: false,
    isLoadingCategories: false,
    isLoadingAdvertiserReports: false,
    isLoadingReportCategoryId: false,
    reportCategoryId: null,
    categories: [],
    advertiserReports: [],
    matchups: [],
    selectedCategory: null
};

const getters = {
    isLoadingData: (state) => state.isLoadingData,
    isLoadingCategories: (state) => state.isLoadingCategories,
    isLoadingAdvertiserReports: (state) => state.isLoadingAdvertiserReports,
    isLoadingReportCategoryId: (state) => state.isLoadingReportCategoryId,
    reportCategoryId: (state) => state.reportCategoryId,
    categories: (state) => state.categories,
    advertiserReports: (state) => state.advertiserReports,
    matchups: (state) => state.matchups,
    selectedCategory: (state) => state.selectedCategory
};

const mutations = {
    setLoadingData(state, payload) {
        state.isLoadingData = payload;
    },
    setLoadingCategories(state, payload) {
        state.isLoadingCategories = payload;
    },
    setLoadingAdvertiserReports(state, payload) {
        state.isLoadingAdvertiserReports = payload;
    },
    setLoadingReportCategoryId(state, payload) {
        state.isLoadingCategoryId = payload;
    },
    setReportCategoryId(state, payload) {
        state.reportCategoryId = payload;
    },
    setCategories(state, payload) {
        state.categories = payload;
    },
    setAdvertiserReports(state, payload) {
        state.advertiserReports = payload;
    },
    setMatchups(state, payload) {
        state.matchups = payload;
    },
    setSelectedCategory(state, payload) {
        if (typeof payload === 'object') {
            state.selectedCategory = payload;
        } else {
            state.selectedCategory = state.categories.find((category) => category.id == payload);
        }
        return true;
    }
};

const actions = {
    async fetchCategories({ commit }) {
        commit('setLoadingCategories', true);
        await getData('/api/matchups/categories').then((response) => {
            console.log('fetchCategories response', response);
            commit('setCategories', response.data.data);

            if (!state.selectedCategory) {
                commit('setSelectedCategory', response[0]);
            }

            commit('setLoadingCategories', false);
        });
    },
    // TODO :: This can probably be removed, we can use the CRUD module to manage matchups
    fetchMatchups({ commit }) {
        commit('setLoadingData', true);
        // TODO :: WIP check with Greg
        getData('/api/matchups?where=category_id=1&with[]=offer:name,network_advertiser_id&with[]=affiliate:name,network_employee_id&with[]=advertiser')
            .then((response) => {
                console.log('fetchMatchups response', response);
                commit('setMatchups', response.data.data);
            })
            .catch((error) => {
                console.error('fetchMatchups error', error);
            })
            .finally(() => {
                commit('setLoadingData', false);
            });
    },
    // TODO :: We can probably remove this - Advertiser Reports are managed by the CRUD module
    async fetchAdvertiserReports({ commit }) {
        commit('setLoadingAdvertiserReports', true);
        // TODO :: JK now it's this one, but it might be better if we do an API call to /api/reports/categories?where=slug=matchups to get the correct ID because it might differ between environments
        await getData('/api/reports?where=report_category_id=1')
            .then((response) => {
                console.log('fetchAdvertiserReports response', response);
                commit('setAdvertiserReports', response);
            })
            .catch((error) => {
                console.error('fetchAdvertiserReports error', error);
            })
            .finally(() => {
                commit('setLoadingAdvertiserReports', false);
            });
    },
    async fetchReportCategoryId({ commit }) {
        commit('setLoadingReportCategoryId', true);
        await getData('/api/reports/categories?where=slug=matchups')
            .then((response) => {
                console.log('fetchReportCategoryId response', response);
                commit('setReportCategoryId', response?.data?.data[0]?.id ?? null);
            })
            .catch((error) => {
                console.error('fetchReportCategoryId error', error);
            })
            .finally(() => {
                commit('setLoadingReportCategoryId', false);
            });

        return true;
    },
    selectCategory({ commit }, category) {
        commit('setSelectedCategory', category);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
