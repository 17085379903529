export default [
    {
        path: '/internal/tools',
        meta: { title: 'MyMadrivo | Tools' },
        children: [
            {
                path: '/internal/tools/matchup/manage-categories',
                name: 'manageMatchupCategories',
                component: () => import('@/views/pages/tools/Matchup/manageCategories.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readMatchups' }
            },
            {
                path: '/internal/tools/matchup/advertiser-reports',
                name: 'advertiserReports',
                component: () => import('@/views/pages/tools/Matchup/ManageAdvertiserReports.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readReports' }
            },
            {
                path: '/internal/tools/matchup',
                name: 'matchup',
                component: () => import('@/views/pages/tools/Matchup/Index.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readMatchups' }
            },
            {
                path: '/internal/tools/matchup/:id',
                name: 'matchupDetails',
                component: () => import('@/views/pages/tools/Matchup/Index.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readMatchups' },
                props: true
            },
            {
                path: '/internal/tools/offerfocus',
                name: 'offerFocus',
                component: () => import('@/views/pages/tools/OfferFocus.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'offerFocus' }
            },
            // TODO :: does this live here?
            {
                path: '/internal/tools/chat',
                name: 'AiChat',
                component: () => import('@/views/pages/tools/AiChat.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'aiChat' }
            },
            {
                path: '/internal/user-map',
                name: 'userMap',
                component: () => import('@/views/pages/tools/UserMap.vue'),
                meta: { isInternal: true, requiresAuth: true }
            }
        ]
    }
];
