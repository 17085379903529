<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useLayout } from '@/layout/composables/layout';

const { layoutConfig, onMenuToggle, layoutState } = useLayout();
const store = useStore();

const mobileMenuActive = computed(() => {
    return layoutState.staticMenuMobileActive.value;
});

import AppMenuItem from './AppMenuItem.vue';

// nesting more items arrays will create submenus
const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'Accounting',
        requiresInternal: true,
        requiresAbility: 'viewAccounting',
        items: [
            {
                label: 'Payment Holds',
                icon: 'pi pi-fw pi-times-circle',
                to: '/internal/accounting/payment-holds'
            },
            {
                label: 'Reconciliations',
                requiresAbility: 'viewReconciliations',
                items: [
                    {
                        label: 'Reports',
                        icon: 'pi pi-fw pi-file',
                        to: '/internal/accounting/reconciliation',
                        requiresAbility: 'viewReconciliations'
                    },
                    {
                        label: 'Advertiser Status',
                        icon: 'pi pi-fw pi-list-check',
                        to: '/internal/accounting/reconciliation/status',
                        requiresAbility: 'viewReconciliations'
                    }
                ]
            }
        ]
    },
    {
        label: 'Advertisers',
        requiresInternal: true,
        items: [
            {
                label: 'Advertiser Search',
                icon: 'pi pi-fw pi-search',
                to: '/internal/advertiser'
            },
            {
                label: 'Advertiser Applications',
                icon: 'pi pi-fw pi-book',
                to: '/internal/advertiser/applications',
                requiresAbility: 'manageAdvertiserApplications'
            }
        ]
    },
    {
        label: 'Publishers',
        requiresInternal: true,
        items: [
            {
                label: 'Publisher Search',
                icon: 'pi pi-fw pi-search',
                to: '/internal/publishers'
            }
        ]
    },
    {
        label: 'Creatives',
        requiresInternal: true,
        requiresAbility: 'manageCreatives',
        items: [
            {
                label: 'Creative Request',
                icon: 'pi pi-fw pi-globe',
                to: '/internal/creative/request'
            }
        ]
    },
    {
        label: 'Newsletters',
        requiresInternal: true,
        requiresAbility: 'manageNewsletters',
        items: [
            {
                label: 'Weekly Spotlight Offers',
                icon: 'pi pi-fw pi-envelope',
                to: '/internal/newsletters/manage/weeklyoffers'
            }
        ]
    },
    {
        label: 'Reports',
        requiresInternal: true,
        requiresAbility: 'readReports',
        items: [
            {
                // TODO :: update label/icon if needed
                label: 'NPS Survey Recipients',
                icon: 'pi pi-fw pi-users',
                to: '/internal/reports/nps-survey-recipients',
                requiresAbility: 'viewNpsSurveyRecipients'
            },
            {
                label: 'Tool Usage',
                icon: 'pi pi-fw pi-hammer',
                to: '/internal/reports/tool-usage',
                requiresAbility: 'viewToolUsage'
            },
            {
                label: 'Offers Without Clicks',
                icon: 'pi pi-fw pi-users',
                to: '/internal/reports/offers-without-clicks',
                requiresAbility: 'zeroClicksReport'
            },
            {
                label: 'Creative Approved No Revenue',
                icon: 'pi pi-fw pi-chart-scatter',
                to: '/internal/reports/creative-approved-no-revenue',
                requiresAbility: 'readCreativeApprovedNoRevenue'
            },
            {
                label: 'Top Offers',
                icon: 'pi pi-fw pi-dollar',
                to: '/internal/reports/top-offers',
                requiresAbility: 'readTopOffers'
            },
            {
                label: 'Cap Report',
                icon: 'pi pi-fw pi-chart-bar',
                to: '/internal/reports/caps',
                requiresAbility: 'readCapReports'
            }
        ]
    },
    {
        label: 'Internal Tools',
        requiresInternal: true,
        requiresAbility: 'internalTools',
        items: [
            {
                // TODO :: does this live here?
                label: 'AI Chat',
                icon: 'pi pi-fw pi-microchip-ai',
                to: '/internal/tools/chat',
                requiresAbility: 'aiChat'
            },
            {
                label: 'Offer Focus',
                icon: 'pi pi-fw pi-search',
                to: '/internal/tools/offerfocus',
                requiresAbility: 'offerFocus'
            },
            {
                label: 'Match Up',
                icon: 'pi pi-fw pi-arrow-right-arrow-left',
                to: '/internal/tools/matchup',
                requiresAbility: 'readMatchups'
            },
            {
                label: 'User Map',
                requiresInternal: true,
                icon: 'pi pi-fw pi-users',
                to: '/internal/user-map'
            }
        ]
    },
    {
        label: 'Ops Tools',
        requiresInternal: true,
        requiresAbility: 'opsTeam',
        items: [
            {
                label: 'Offers',
                items: [
                    {
                        label: 'Approvals',
                        icon: 'pi pi-envelope',
                        to: '/internal/opstools/offers',
                        target: '_blank'
                    },
                    {
                        label: 'Visibility',
                        icon: 'pi pi-eye',
                        to: '/internal/opstools/offer/visibility',
                        target: '_blank'
                    },
                    {
                        label: 'Direct Linking',
                        icon: 'pi pi-link',
                        to: '/internal/opstools/offers/direct-linking',
                        target: '_blank'
                    },
                    {
                        label: 'IO Generator',
                        icon: 'pi pi-file',
                        to: '/internal/opstools/offers/ios/create',
                        target: '_blank'
                    }
                ]
            }
        ]
    },
    {
        label: 'Suppressions',
        requiresInternal: true,
        requiresAbility: 'readSuppressionLists', // TODO :: CHECK ALL SUPPRESSION PERMISSIONS
        items: [
            {
                label: 'Suppression Acquisition',
                icon: 'pi pi-fw pi-ban',
                to: '/internal/suppression-lists',
                requiresAbility: 'readSuppressionLists'
            },
            {
                label: 'Suppression Violations',
                icon: 'pi pi-fw pi-exclamation-triangle',
                to: '/internal/suppression-violations',
                requiresAbility: 'readSuppressionLists'
            },
            {
                label: 'System Credentials',
                icon: 'pi pi-fw pi-key',
                to: '/internal/system-credentials',
                requiresAbility: 'readSystemCredentials'
            }
        ]
    },
    {
        label: 'Wiki',
        requiresInternal: true,
        requiresAbility: 'canEditWiki',
        icon: 'pi pi-file',
        to: '/wiki',
        items: [
            {
                label: 'Client Resource Guide',
                icon: 'pi pi-book',
                to: '/wiki'
            }
        ]
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <div class="flex justify-content-center my-3">
            <button v-if="mobileMenuActive" class="p-link" @click="onMenuToggle()">
                <i class="pi pi-times-circle" style="font-size: 1.25rem"></i>
            </button>
        </div>
        <div v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </div>
    </ul>
</template>

<style lang="scss" scoped></style>
