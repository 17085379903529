export default [
    {
        path: '/internal/suppression-lists',
        meta: { title: 'MyMadrivo | Suppressions' },
        children: [
            {
                path: '/internal/suppression-lists',
                name: 'suppressionLists',
                component: () => import('@/views/pages/Suppressions/SuppressionLists.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readSuppressionLists' }
            },
            {
                path: '/internal/suppression-lists/:id',
                name: 'suppressionListDetails',
                props: (route) => ({ id: route.params.id }),
                component: () => import('@/views/pages/Suppressions/SuppressionListDetails.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readSuppressionLists' }
            },
            {
                path: '/internal/system-credentials',
                name: 'systemCredentials',
                component: () => import('@/views/pages/Advertisers/SystemCredentials.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readSystemCredentials' }
            },
            {
                path: '/internal/suppression-violations',
                name: 'suppressionViolations',
                component: () => import('@/views/pages/Suppressions/SuppressionViolations.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'readSuppressionLists' }
            }
        ]
    }
];
