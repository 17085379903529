import { getData, deleteData } from '@/api';
import cloneDeep from 'lodash/cloneDeep';

const defaultCrudModule = {
    namespaced: true,
    state: {
        endpoints: {
            read: '',
            create: '',
            update: '',
            delete: ''
        },
        resources: [],
        isLoading: false,
        resourceLabel: '',
        mapping: null,
        tableData: []
    },
    getters: {
        resources: (state) => state.resources,
        isLoading: (state) => state.isLoading,
        resourceLabel: (state) => state.resourceLabel,
        tableData: (state) => state.tableData
    },
    mutations: {
        setEndpoints(state, endpoints) {
            state.endpoints = endpoints;
        },
        setResources(state, resources) {
            state.resources = resources;
        },
        setLoading(state, payload) {
            state.isLoading = payload;
        },
        setResourceLabel(state, label) {
            state.resourceLabel = label;
        },
        setMapping(state, mapping) {
            state.mapping = mapping;
        },
        setTableData(state, tableData) {
            state.tableData = tableData;
        }
    },
    actions: {
        setEndpoints({ commit }, { endpoints }) {
            commit('setEndpoints', endpoints);
        },
        setBaseEndpoint({ commit }, { baseEndpoint, readParams = null }) {
            const endpoints = {
                create: baseEndpoint,
                read: baseEndpoint,
                update: baseEndpoint,
                delete: baseEndpoint
            };
            if (readParams && readParams.length > 0) {
                endpoints.read = `${baseEndpoint}?${readParams.join('&')}`;
            }
            commit('setEndpoints', endpoints);
        },
        async fetchCrudData({ commit, state }) {
            try {
                console.log('Fetching CRUD data...');
                console.log('Endpoints:', state.endpoints);
                const response = await getData(state.endpoints.read);
                let data = response?.data?.data ?? response ?? [];
                if (state.mapping && typeof state.mapping === 'function') {
                    data = data.map(state.mapping);
                }
                commit('setResources', data);
            } catch (error) {
                console.error('Error fetching CRUD data:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteResource({ state }, id) {
            try {
                await deleteData(`${state.endpoints.delete}/${id}`);
            } catch (error) {
                console.error('Error deleting resource:', error);
            }
        },
        handleDataTableUpdate({ commit, dispatch }, event) {
            if (!event) return;
            commit('setTableData', event);
        }
    }
};

const createCrudModule = () => cloneDeep(defaultCrudModule);

export { defaultCrudModule as default, createCrudModule };
