import { toRefs, reactive, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { debounce } from 'lodash';

const layoutConfig = reactive({
    ripple: false,
    darkTheme: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    theme: 'lara-light-indigo',
    scale: 14,
    activeMenuItem: null
});

const layoutState = reactive({
    staticMenuDesktopInactive: true,
    overlayMenuActive: true,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    subSidebarActive: false
});
const mobileBreakpoint = 992; // TODO :: pull from the variables stylesheet (breakpointLg)
const isMobileScreen = ref(window.innerWidth <= mobileBreakpoint);

const updateScreenSize = debounce(() => {
    isMobileScreen.value = window.innerWidth <= mobileBreakpoint;
}, 500);

export function useLayout() {
    const changeThemeSettings = (theme, darkTheme) => {
        layoutConfig.darkTheme = darkTheme;
        layoutConfig.theme = theme;
    };

    const setScale = (scale) => {
        layoutConfig.scale = scale;
    };

    const setActiveMenuItem = (item) => {
        layoutConfig.activeMenuItem = item.value || item;
    };

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
        }

        if (window.innerWidth > mobileBreakpoint) {
            layoutState.staticMenuMobileActive = false;
            layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
        } else {
            layoutState.staticMenuDesktopInactive = true;
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }
    };

    const onSubSidebarToggle = (event) => {
        if (typeof event === 'boolean') {
            layoutState.subSidebarActive = event;
            return;
        }
        layoutState.subSidebarActive = !layoutState.subSidebarActive;
    };

    const isSubSidebarActive = computed(() => layoutState.subSidebarActive);

    const isSidebarActive = computed(() => !layoutState.staticMenuDesktopInactive || layoutState.staticMenuMobileActive);

    const isDarkTheme = computed(() => layoutConfig.darkTheme);

    onMounted(() => {
        window.addEventListener('resize', updateScreenSize);
    });

    return { layoutConfig: toRefs(layoutConfig), layoutState: toRefs(layoutState), changeThemeSettings, setScale, onMenuToggle, isSidebarActive, isDarkTheme, setActiveMenuItem, onSubSidebarToggle, isSubSidebarActive, isMobileScreen };
}
